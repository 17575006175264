import React, { useContext } from "react";
import { graphql, Link } from "gatsby";
import { css } from "@emotion/react";
import { ModalRoutingContext } from "../context/modal-routing";
import Close from "../assets/svg/close.inline.svg";

import Scrollbar from "../containers/Scrollbar";

const ingredientsWrap = css`
  height: 100%;
  display: flex;
  padding: 45px 25px 25px;
  > div {
    width: 100%;
  }
  table {
    width: 100%;
    margin-bottom: 35px;
  }
  tr:nth-child(even) {
    background-color: #ececec;
  }
  td:nth-child(2) {
    min-width: 100px;
  }
  @media (max-width: 550px) {
    padding-left: 10px;
    padding-right: 10px;
    table {
      padding-right: 40px;
    }
  }
`;

const tableCss = (props) => css`
  th {
    top: 0;
    position: sticky;
    background-color: ${props.color};
  }
`;

const tablesWrap = css`
  width: 100%;
  @media (min-width: 1600px) {
    display: grid;
    grid-template-areas:
      "a b"
      "a c"
      "a d"
      "a .";
    grid-template-columns: 1fr 1fr;
    > div:nth-child(1) {
      grid-area: a;
    }
    > div:nth-child(2) {
      grid-area: b;
    }
    > div:nth-child(3) {
      grid-area: c;
    }
    > div:nth-child(4) {
      grid-area: d;
    }
  }
  @media (max-width: 550px) {
    width: auto;
  }
`;
const closeButton = css`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  display: flex;
  padding: 0;
  cursor: pointer;
  svg {
    height: 24px;
    width: 24px;
    fill: #3a383c;
  }
  @media (max-width: 768px) {
    padding: 10px;
    background-color: #fff;
    top: 0;
    right: 0;
  }
`;

const LisftofIngredients = (props) => {
  const { allStrapiListOfIngredients } = props.data;
  const { modal, closeTo } = useContext(ModalRoutingContext);

  const colors = ["#ffd491", "#e44969", "#8bf6ff", "#afffba"];
  const gridAreas = ["a", "b", "c", "d"];

  return (
    <div css={ingredientsWrap}>
      <Scrollbar wrapper={false}>
        <div css={tablesWrap}>
          {allStrapiListOfIngredients.edges.map((el, i) => (
            <div key={i} css={{ gridArea: gridAreas[i] }}>
              <table css={tableCss({ color: colors[i] })}>
                <thead>
                  <tr>
                    <th>INGREDIENT ({el.node.name})</th>
                    <th>CAS</th>
                    <th>FUNCTION</th>
                  </tr>
                </thead>
                <tbody>
                  {el.node.ingredients_row.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>{row.ingredient}</td>
                      <td>{row.cas}</td>
                      <td>{row.function}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </Scrollbar>
      {modal ? (
        <Link
          to={closeTo}
          state={{ closingModal: true, scrollPosition: window.pageYOffset }}
          css={closeButton}
        >
          <Close />
        </Link>
      ) : (
          ""
        )}
    </div>
  );
};

export default LisftofIngredients;

export const query = graphql`
  query IngredientsQuery {
    allStrapiListOfIngredients {
      edges {
        node {
          name
          ingredients_row {
            cas
            function
            ingredient
          }
        }
      }
    }
  }
`;
